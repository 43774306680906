@import "~@kruu/fotobox-collagen/css/konva.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.editor {
  border: solid 1px black;
  background-color: #282835;
  font-size: .8rem;
  white-space: pre;
  color: white;
  //max-height: calc(100vh - 3.6rem);
  overflow-y: scroll;

}

.editor::-webkit-scrollbar {
  display: none;
}

.string { color: #50fa7b; }
.number { color: #8be9fd; }
.boolean { color: #ffb86c; }
.null { color: magenta; }
.key { color: #bd93f9; }